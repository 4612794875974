import {
  JSXElementConstructor,
  ButtonHTMLAttributes,
  forwardRef,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "@components/Ui/Spinner";

type ButtonProps = {
  className?: string;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "xs" | "sm" | "base" | "lg";
  Component?: string | JSXElementConstructor<any>;
  disabled?: boolean;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement> & { target?: string };

const Button = forwardRef(
  (
    {
      children,
      className = "",
      variant = "primary",
      size = "base",
      Component = "button",
      disabled = false,
      isLoading = false,
      ...rest
    }: PropsWithChildren<ButtonProps>,
    buttonRef,
  ) => {
    return (
      <Component
        ref={buttonRef}
        disabled={disabled}
        className={twMerge(
          "relative inline-block border font-normal outline-0 disabled:cursor-not-allowed disabled:border-gray-600 disabled:opacity-50",
          variant === "secondary" &&
            "border-gray-500 hover:bg-gray-600/50 disabled:bg-black/0",
          variant === "primary" &&
            "border-purple-700 bg-purple-700 hover:bg-purple-700/80 disabled:bg-purple-700",
          variant === "tertiary" &&
            "border-gray-700 bg-gray-700 hover:bg-gray-600 disabled:bg-gray-800",
          size === "xs" && "rounded-md px-5 py-[9px]",
          size === "sm" && "rounded-md px-6 py-2.5",
          size === "base" && "rounded-md px-7 py-4",
          size === "lg" && "rounded-lg p-8",
          !disabled && "active:translate-y-px",
          className,
        )}
        {...rest}
      >
        <span
          className={twMerge(
            "flex items-center justify-center space-x-2",
            isLoading && "opacity-0",
          )}
        >
          {children}
        </span>
        {isLoading && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <Spinner
              className={twMerge(
                size === "sm" && "text-base",
                size === "base" && "text-2xl",
                size === "lg" && "text-3xl",
              )}
            />
          </span>
        )}
      </Component>
    );
  },
);

Button.displayName = "Button";
export default Button;
