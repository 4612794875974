import Image from "next/legacy/image";
import { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ButtonMenu from "@components/UserButton/ButtonMenu";
import useOnClickOutside from "@hooks/use-click-outside";

export default () => {
  const { user } = useAuth0();
  const { name, picture, email } = user || {};
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  return (
    <>
      {isOpen && (
        <div className="fixed left-0 top-0 z-40 h-full w-full bg-black opacity-70" />
      )}
      {/* TODO: remove playwright mask sometime in the future and see if the 1px bug is gone */}
      <div className="relative z-40" ref={ref}>
        <a
          className="cursor-pointer space-x-2.5 text-sm"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Open user menu"
          data-apply-playwright-mask
        >
          {picture && (
            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full">
              <Image
                className="rounded-full"
                src={picture}
                alt="user picture"
                width={32}
                height={32}
              />
            </div>
          )}
        </a>

        <ButtonMenu
          isOpen={isOpen}
          user={{ name, picture, email }}
          closeFunction={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </>
  );
};
