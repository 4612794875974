import { useFormControlContext } from "@components/Context/FormControl";
import { ChangeEvent, useState } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@components/Ui/Icon";
import { IconName } from "icons/types";
import Spinner from "@components/Ui/Spinner";

type TextInputProps = {
  value?: string;
  id?: string;
  type?: "text" | "tel" | "search";
  hasError?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  pattern?: string;
  required?: boolean;
  compact?: boolean;
  slim?: boolean;
  inputMode?: "text" | "numeric";
  icon?: IconName;
  isLoading?: boolean;
  autoComplete?: string;
};

export default ({
  value,
  onChange,
  onFocus,
  id,
  type = "text",
  hasError,
  placeholder,
  name,
  readOnly,
  disabled,
  autoFocus,
  tabIndex,
  pattern = ".*",
  required,
  compact = false,
  slim = false,
  inputMode,
  icon,
  isLoading,
  autoComplete,
}: TextInputProps) => {
  const formContext = useFormControlContext();
  const [localValue, setLocalValue] = useState(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  return (
    <div
      className={twMerge(
        "relative w-full font-normal",
        disabled && "opacity-40",
      )}
    >
      {!isLoading && icon && (
        <Icon
          className={twMerge(
            "absolute left-5 top-1/2 -translate-y-1/2 text-gray-200",
            slim && "left-3",
          )}
          name={icon}
        />
      )}
      {isLoading && (
        <div
          className={twMerge(
            "absolute left-5 top-1/2 flex -translate-y-1/2",
            slim && "left-3",
          )}
        >
          <Spinner />
        </div>
      )}
      <input
        className={twMerge(
          "peer w-full rounded-md border border-transparent bg-gray-600/50 p-5 text-base text-white outline-0 transition-colors duration-150 placeholder:text-base placeholder:text-gray-300 read-only:!border-gray-600/50 read-only:!bg-gray-600/50 read-only:text-gray-300 hover:bg-gray-600/90	focus:border-gray-500 focus:bg-gray-600/90 disabled:cursor-not-allowed sm:text-base",
          hasError && "border-red focus:border-red",
          compact && "px-5 pb-3 pt-7",
          slim && "border-none px-5 py-2.5",
          icon && "pl-[52px]",
          slim && icon && "pl-[42px]",
        )}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        id={id || formContext.id}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        pattern={pattern}
        required={required}
        placeholder={!compact ? placeholder : undefined}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        inputMode={inputMode}
        autoComplete={autoComplete}
      />
      {compact && (
        <label
          htmlFor={id || formContext.id}
          className={twMerge(
            "pointer-events-none absolute left-5 right-5 top-1/2 -translate-y-1/2 overflow-hidden whitespace-nowrap text-base text-gray-300 transition-all peer-focus:-translate-y-[calc(50%+theme(space.3))] peer-focus:text-xs sm:text-base",
            (value !== undefined ? value : localValue) &&
              "-translate-y-[calc(50%+theme(space.3))] text-xs sm:text-xs",
          )}
        >
          {placeholder}
        </label>
      )}
    </div>
  );
};
