import Row from "@components/Ui/Row";
import { useVendorContext } from "@contexts/vendor-context";
import navItems, { getActivePageIds } from "@utils/navigation";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";
import Clickable from "@components/Ui/Clickable";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const { selectedVendor } = useVendorContext();
  const { pathname, asPath } = useRouter();
  const [activePageId, activeSubPageId] = getActivePageIds(pathname);

  const { subItems } = navItems.find(({ id }) => id === activePageId) || {
    subItems: [],
  };
  const isFirstPage =
    subItems.findIndex(({ id }) => id === activeSubPageId) < 1;

  if (!selectedVendor) return null;

  const items: { href?: string; name: string }[] = [];

  const levels = asPath.split("/").length - 1;

  if (!isFirstPage) {
    items.push({
      href: undefined,
      name: activePageId.replace("_", " "),
    });
  }

  if ((isFirstPage && levels > 2) || levels > 3) {
    const link =
      activePageId !== activeSubPageId
        ? `${activePageId}/${activeSubPageId.replace("_", "-")}`
        : activePageId;
    items.push({
      href: `/${selectedVendor}/${link}`,
      name: activeSubPageId.replace("_", " "),
    });
  }

  return (
    <Row className="gap-2 text-sm sm:text-sm">
      {items.map(({ href, name }, i) => (
        <Row className="gap-2" key={`${href}_${i}`}>
          {i !== 0 && (
            <div className="text-2xl !leading-[0] sm:text-2xl">•</div>
          )}
          <div className="text-white">
            <Clickable
              className={twMerge(
                "inline-block transition-colors duration-75 first-letter:uppercase",
                !i && "text-gray-300",
              )}
              href={i ? href : undefined}
            >
              {t(name)}
            </Clickable>
          </div>
        </Row>
      ))}
    </Row>
  );
};
