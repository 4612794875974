import { BillStatusType } from "@type-defs/contracts";
import { twMerge } from "tailwind-merge";

type StatusBadgeType = "success" | "warning" | "error" | "default";

const getContractBillStatusType = (status: BillStatusType): StatusBadgeType => {
  switch (status) {
    case "CANCELED":
    case "DELETED":
    case "OVERDUE":
      return "error";
    case "PAID":
      return "success";
    case "PENDING":
      return "warning";
    default:
      return "default";
  }
};

export default ({ status }: { status: BillStatusType }) => {
  const type = getContractBillStatusType(status);
  return (
    <span className="gap-2 capitalize row-left">
      <span
        className={twMerge(
          "h-1.5 w-1.5 rounded-xl",
          type === "warning" && "bg-orange",
          type === "error" && "bg-red",
          type === "success" && "bg-green",
          type === "default" && "bg-gray-300",
        )}
      />
      {status.toLowerCase()}
    </span>
  );
};
