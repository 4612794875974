import React from "react";
import { useVendorContext } from "@contexts/vendor-context";
import VendorListItem from "@components/VendorListItem";

type Props = {
  closeFunction: () => void;
};

export default ({ closeFunction }: Props) => {
  const { selectedVendor, vendors, getVendorSettings, multiVendorKey } =
    useVendorContext();

  if (!vendors || vendors.length < 2) {
    return null;
  }

  const handleSelectVendor = (vendor: string) => {
    closeFunction();
    // "just" needs to go to /[vendor] - could persist path and query string
    // router.push(`/${vendor}/analytics`);
    // due to several issues with front end and back end - https://app.shortcut.com/sesamy/story/3705/swr-cache-gets-sticky-when-changing-vendors-in-insights-portal#activity-3789
    if (multiVendorKey === vendor) {
      window.location.replace(`/${vendor}`);
      return;
    }
    window.location.replace(`/${vendor}/analytics`);
  };

  return (
    <div
      data-testid="user-menu-vendors-list"
      className="custom-scrollbar scrollbar-gray-700 max-h-[70vh] space-y-px overflow-auto py-1.5"
    >
      {vendors?.map((vendor) => (
        <VendorListItem
          onClick={() => handleSelectVendor(vendor)}
          vendor={getVendorSettings(vendor)}
          key={vendor}
          isSelected={selectedVendor === vendor}
        />
      ))}
    </div>
  );
};
