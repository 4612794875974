/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: "media",
  theme: {
    screens: {
      sm: "640px", // old sm: "768px",
      md: "1265px", // old md: "1024px", // new md: 1280px - 15px (forced scrollbar)
      lg: "1920px", // old lg: "1280px",
      xl: "1920px", // same as lg, old xl: "1520px",
      "2xl": "1920px", // same as lg, old "2xl": "1920px",
    },
    colors: {
      primary: "#7D68F4",
      primaryHover: "#7e69f4",
      secondary: "#D0EE25",
      textOnPrimary: "#ffffff",
      black: "#000000",
      white: "#ffffff",
      gray: {
        100: "#f8f9fb",
        200: "#BFBCD7",
        300: "#88869F",
        400: "#4b4a58",
        500: "#3b3946",
        600: "#282834",
        700: "#1E1E27",
        800: "#14141A",
        900: "#08080E",
      },
      purple: {
        100: "#eeecfd",
        200: "#bfb9e2",
        300: "#cac1f9",
        400: "#b1a5f9",
        500: "#8C78FB",
        600: "#9B8BF9",
        700: "#7D68F4", // same as primary
        800: "#433881",
        900: "#252048",
      },
      pear: {
        100: "#d9e1ad",
        200: "#cddf65",
        300: "#C3DF25",
      },
      yellow: "#F3BB53",
      red: "#FC5A5A",
      green: "#36BF76",
      // Charts and data vizualization colors
      lime: "#C3DF25",
      olive: "#6EEA92",
      orange: "#EE7B30",
      crimson: "#F3538B",
      pink: "#E9A8E8",
      baby: "#6CE9FC",
      gradient: {
        50: "#7d68f4", // same as purple.700 & primary
        100: "#9B8BF9", // same as purple.600
        150: "#b1a5f9", // same as purple.400
        200: "#edeaff", // close to purple.100
        250: "#eaffff", // cyan bright
        300: "#a5e2f9",
        350: "#68d4f4",
        400: "#68bff4", // cyan deep
        450: "#68f4ad", // green bright
        500: "#a5f9ce",
        550: "#d7ffe8", // green deep
        600: "#ffeafa", // crimson bright
        650: "#f9a5d9",
        700: "#f468af", // crimson deep
        750: "#433881", // same as purple.800 (repeat the chain)
      },
      transparent: "transparent",
    },
    extend: {
      maxWidth: {
        wide: "1680px",
      },
      fontFamily: {
        sans: [
          '"KHTeka"',
          '"Helvetica Neue"',
          "HelveticaNeue",
          '"TeX Gyre Heros"',
          "TeXGyreHeros",
          "FreeSans",
          '"Nimbus Sans L"',
          '"Liberation Sans"',
          "Arimo",
          "Helvetica",
          "sans-serif",
        ],
      },
      fontSize: {
        xs: [
          "0.75rem",
          {
            lineHeight: "135%",
          },
        ], // 12px
        sm: [
          "0.875rem",
          {
            lineHeight: "120%",
          },
        ], // 14px
        base: [
          "1rem",
          {
            lineHeight: "120%",
          },
        ], // 16px | 0.0625rem per px unit
        lg: [
          "1.125rem",
          {
            lineHeight: "120%",
          },
        ], // 18px
        xl: [
          "1.25rem",
          {
            lineHeight: "120%",
          },
        ], // 20px
        "2xl": [
          "1.5rem",
          {
            lineHeight: "120%",
          },
        ], // 24px
        "3xl": [
          "2rem",
          {
            lineHeight: "120%",
          },
        ], // 32px
        "4xl": [
          "3rem",
          {
            lineHeight: "100%",
            letterSpacing: "-0.0625rem",
          },
        ], // 48px
        "5xl": [
          "3.5rem",
          {
            lineHeight: "100%",
            letterSpacing: "-0.0625rem",
          },
        ], // 56px
        "6xl": [
          "5.125rem",
          {
            lineHeight: "100%",
            letterSpacing: "-0.125rem",
          },
        ], // 82px
        "7xl": [
          "5.625rem",
          {
            lineHeight: "100%",
            letterSpacing: "-0.125rem",
          },
        ], // 90px
      },
      lineHeight: {
        currency: "144px",
      },
      boxShadow: {
        card: "0px 2px 3px -1px rgb(213 213 213)",
      },
      borderRadius: {
        sm: "0.1875rem",
        lg: "0.625rem",
        "2xl": "1.25rem",
      },
      letterSpacing: {
        sm: "0.02em",
      },
      keyframes: {
        makeVisible: {
          "0%": {
            visibility: "hidden",
            opacity: "0%",
          },
          "50%": {
            visibility: "visible",
          },
          "100%": {
            visibility: "visible",
            opacity: "100%",
          },
        },
      },
      animation: {
        appear: "makeVisible 200ms linear 25ms forwards",
      },
    },
  },
};
