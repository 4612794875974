import { Range as RangeDate } from "react-date-range";

type Props = {
  selectFunc: (range: RangeDate) => void;
};

export default ({ selectFunc }: Props) => {
  const selectLastWeek = () => {
    const lastWeek = {
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      key: "selection",
    };
    selectFunc(lastWeek);
  };

  const selectLastMonth = () => {
    const lastMonth = {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
    };
    selectFunc(lastMonth);
  };

  const selectLastThreeMonths = () => {
    const lastThreeMonths = {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
    };
    selectFunc(lastThreeMonths);
  };

  const selectToday = () => {
    const today = new Date();
    const lastDay = {
      startDate: new Date(new Date().setHours(today.getHours() - 1)),
      endDate: today,
    };
    selectFunc(lastDay);
  };

  return (
    <div className="flex space-x-2.5 text-sm">
      <button
        onClick={selectLastThreeMonths}
        className="rounded-lg bg-gray-600 px-4 py-1.5"
      >
        Last three months
      </button>
      <button
        onClick={selectLastMonth}
        className="rounded-lg bg-gray-600 px-4 py-1.5"
      >
        Last month
      </button>
      <button
        onClick={selectLastWeek}
        className="rounded-lg bg-gray-600 px-4 py-1.5"
      >
        Last week
      </button>
      <button
        onClick={selectToday}
        className="rounded-lg bg-gray-600 px-4 py-1.5"
      >
        Today
      </button>
    </div>
  );
};
