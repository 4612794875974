import { useVendorContext } from "@contexts/vendor-context";
import RangeDatePicker from "@components/DateRangePicker/DateRangePicker";
import { Range as RangeDate } from "react-date-range";
import { useSelectedDatesContext } from "@contexts/selected-dates-context";
import Greeting from "@components/Greeting";
import VendorLink from "@components/VendorLink";
import { twMerge } from "tailwind-merge";
import Row from "@components/Ui/Row";
import UserButton from "@components/UserButton/UserButton";
import Column from "@components/Ui/Column";
import Breadcrumb from "@components/Breadcrumb";
import Icon from "@components/Ui/Icon";
import { ReactNode } from "react";
import GlobalSearch from "@components/GlobalSearch";

type PageHeaderProps = {
  showDatePicker?: boolean;
  headline?: string | JSX.Element | null;
  actions?: JSX.Element;
  goBackLink?: string;
  className?: string;
  children?: ReactNode;
  hideGlobalSearch?: boolean;
};

export default ({
  showDatePicker,
  headline,
  actions,
  goBackLink,
  className = "",
  children,
  hideGlobalSearch,
}: PageHeaderProps) => {
  const { selectedDates, handleDatesUpdate } = useSelectedDatesContext();
  const { selectedVendor } = useVendorContext();

  const handleDateChange = async (value: RangeDate) => {
    const { startDate, endDate } = value;
    handleDatesUpdate({ startDate, endDate });
  };

  return (
    <div
      data-testid="page-header"
      className={twMerge("sticky top-0 z-20 bg-black py-4", className)}
    >
      <Row
        className="min-h-[46px] w-full !flex-nowrap !justify-between gap-2"
        up
        left
      >
        <Row className="gap-4" left>
          {goBackLink && (
            <VendorLink
              href={goBackLink}
              className="rounded-md bg-gray-600/50 p-2 transition-colors duration-150 hover:bg-gray-600/90 md:p-2.5"
              aria-label="Go back"
              title="Go back"
            >
              <span className="flex h-6 w-6 items-center justify-center">
                <Icon name="chevron-left" />
              </span>
            </VendorLink>
          )}
          <Column left>
            <Breadcrumb />
            <Greeting headline={headline} />
          </Column>
        </Row>

        <Row className="gap-4">
          {!hideGlobalSearch && <GlobalSearch />}

          {selectedVendor && showDatePicker && (
            <div className="relative z-10 -my-px">
              <RangeDatePicker
                onChange={handleDateChange}
                initDates={selectedDates}
              />
            </div>
          )}

          {actions && <div className="gap-4 row-right">{actions}</div>}

          <UserButton />
        </Row>
      </Row>
      {children}
    </div>
  );
};
