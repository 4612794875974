import { AnchorHTMLAttributes, PropsWithChildren } from "react";
import { useVendorContext } from "@contexts/vendor-context";
import Link from "next/link";

type Props = {
  passHref?: boolean;
  legacyBehavior?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement> &
  PropsWithChildren;

export default ({
  href,
  children,
  title,
  className,
  passHref,
  legacyBehavior,
  ...rest
}: Props) => {
  const { selectedVendor } = useVendorContext();

  return (
    <Link
      className={className}
      href={`/${selectedVendor}${href}`}
      passHref={passHref}
      legacyBehavior={legacyBehavior}
      title={title}
      {...rest}
    >
      {children}
    </Link>
  );
};
