const API_URL = process.env.NEXT_PUBLIC_STATS_API_V2;

export const fetchSearchResults = async ({
  query,
  accessToken,
  limit,
}: {
  query: string;
  accessToken: string;
  limit?: number;
}) => {
  const response = await fetch(
    `${API_URL}/search?query=${query}${limit ? `&limit=${limit}` : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  const data = await response.json();

  // Skip transactions for now
  delete data["Transactions"];
  delete data["Transaction Errors"];

  return data;
};
