import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import LogoutButton from "@components/LogoutButton";
import VendorButtons from "@components/UserButton/VendorButtons";
import Container from "@components/Ui/Container";

type User = {
  name: string | null | undefined;
  email: string | null | undefined;
  picture: string | null | undefined;
};

type ButtonMenuProps = {
  isOpen: boolean;
  background?: string;
  user: User;
  closeFunction: () => void;
};

export default ({ isOpen, user, closeFunction }: ButtonMenuProps) => {
  const { t } = useTranslation();
  const { name, email, picture } = user;

  if (isOpen) {
    return (
      <div className="absolute right-0 top-full z-40 w-[430px]">
        <Container borderRadius="lg" className="mt-4">
          <div data-testid="user-menu" className="px-2">
            <div className="flex items-center space-x-4 px-4 py-6">
              <div className="flex-shrink-0">
                {picture && name && (
                  <Image
                    className="rounded-full border-2 border-white"
                    src={picture}
                    alt={name}
                    layout="fixed"
                    width={64}
                    height={64}
                  />
                )}
              </div>
              <div className="w-full min-w-0 space-y-1.5 column-left">
                <div className="font-medium">
                  {name?.split(" ").slice(0, 2).join(" ")}
                </div>
                <div
                  className="overflow-hidden text-ellipsis text-sm text-gray-200"
                  data-testid="user-email"
                >
                  {email}
                </div>
                <LogoutButton className="pt-1" text={t("sign_out")} />
              </div>
            </div>
            <VendorButtons closeFunction={closeFunction} />
          </div>
          <div className="mx-2 border-t border-gray-600" />
          <div className="space-x-1.5 p-6 text-xs text-gray-300 row-left sm:text-2xl">
            <a
              className="text-xs hover:text-gray-200"
              href="https://sesamy.com/privacy-policy"
              target="_blank"
            >
              {t("privacy_policy")}
            </a>
            <span className="leading-[0]">•</span>
            <a
              className="text-xs hover:text-gray-200"
              href="https://sesamy.com/terms-and-conditions"
              target="_blank"
            >
              {t("terms_and_conditions")}
            </a>
          </div>
        </Container>
      </div>
    );
  }
  return null;
};
