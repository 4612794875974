import React, { useState, useRef, useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange as DateRange17, Range as RangeDate } from "react-date-range";
import { isBefore, isEqual } from "date-fns";
import useOnClickOutside from "@hooks/use-click-outside";
import QuickDatePickLinks from "@components/DateRangePicker/QuickDatePickLinks";
import { shortDateFormat } from "@utils/numberFormat";
import StyledDateRangePicker from "@components/DateRangePicker/StyledDateRangePicker";
import tailwindConfig from "../../tailwind.config";
import Button from "@components/Ui/Button";
import resolveConfig from "tailwindcss/resolveConfig";
import Icon from "@components/Ui/Icon";

const tailwindStyles = resolveConfig(tailwindConfig) as any;

// React 18 TypeScript dance
const DateRange: any = DateRange17;

type Selection = {
  [key: string]: RangeDate;
};

type Props = {
  onChange: (val: RangeDate) => void;
  initDates?: { startDate: Date; endDate: Date };
};

const twColors = tailwindStyles.theme.colors;

export default (props: Props) => {
  const { onChange, initDates } = props;

  const ref = useRef(null);

  const [ranges, setRanges] = useState<RangeDate[]>([
    {
      startDate: initDates?.startDate || new Date(),
      endDate: initDates?.endDate,
      key: "selection",
    },
  ]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(
    `dd/mm/yyyy - dd/mm/yyyy`,
  );

  const handleSelect = (ranges: Selection) => {
    const { selection } = ranges;
    const { startDate, endDate } = selection;
    if (startDate && endDate) {
      setButtonText(
        `${shortDateFormat(`${startDate}`)} - ${shortDateFormat(`${endDate}`)}`,
      );
      setRanges([selection]);
    }
  };

  const handleClickOutside = () => {
    if (!isOpen) return;

    setIsOpen(false);
    setRanges([
      {
        startDate: initDates?.startDate,
        endDate: initDates?.endDate,
        key: "selection",
      },
    ]);
    setButtonText(
      `${shortDateFormat(`${initDates?.startDate}`)} - ${shortDateFormat(
        `${initDates?.endDate}`,
      )}`,
    );
  };

  const handleConfirm = () => {
    const { startDate, endDate } = ranges[0];
    if (startDate && endDate) {
      if (isBefore(startDate, endDate) || isEqual(startDate, endDate)) {
        onChange(ranges[0]);
        setIsOpen(false);
      }
    }
  };

  const handleQuickSelect = (range: RangeDate) => {
    onChange(range);
    setIsOpen(false);
  };

  useEffect(() => {
    if (initDates) {
      const { startDate, endDate } = initDates;
      setButtonText(
        `${shortDateFormat(`${startDate}`)} - ${shortDateFormat(`${endDate}`)}`,
      );
      setRanges([{ startDate, endDate, key: "selection" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initDates]);

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {isOpen && (
        <div className="fixed left-0 top-0 h-full w-full bg-black opacity-70" />
      )}
      <div className="relative" ref={ref}>
        <button
          className="relative max-w-full rounded-md bg-gray-800 px-5 py-2.5 text-sm text-gray-100"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Select dates"
        >
          <div className="!flex-nowrap space-x-5 row">
            <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap font-medium">
              {buttonText}
            </span>
            <Icon
              className="text-xs text-gray-300"
              name={isOpen ? "chevron-up" : "chevron-down"}
            />
          </div>
        </button>
        {isOpen && (
          <div className="absolute right-0 top-full mt-3 rounded-xl bg-gray-800 p-4">
            <StyledDateRangePicker>
              <DateRange
                ranges={ranges}
                displayMode="dateRange"
                months={2}
                direction="horizontal"
                onChange={handleSelect}
                dateDisplayFormat="P"
                showDateDisplay={false}
                maxDate={new Date()}
                minDate={new Date(2022, 3, 15)}
                rangeColors={[twColors.gray[600]]}
                weekStartsOn={1}
                weekdayDisplayFormat="EEEEEE"
                monthDisplayFormat="MMMM"
              />
            </StyledDateRangePicker>
            <div className="-mx-4 mb-5 border-t border-gray-700" />
            <div className="flex w-full items-center justify-between px-3">
              <QuickDatePickLinks selectFunc={handleQuickSelect} />
              <Button onClick={handleConfirm} size="sm">
                Apply dates
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
