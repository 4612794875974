/** @type {import('tailwindcss').Config} */
module.exports = {
  plugins: [require("@ape-egg/tailwind-rows-columns")],
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      screens: {
        short: {
          raw: "(max-height: 900px) and (min-width: 640px)", // 640px = sm. Prevent this from ever triggering on phones
        },
        portait: {
          raw: "(orientation: portrait)",
        },
      },
      backgroundImage: {
        tick: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzIgMUw0Ljk5OTg0IDguMzMzMzNMMS42NjY1IDUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=')",
      },
      minHeight: {
        1: "3.5rem",
      },
    },
  },
  presets: [require("./tailwind.shared.config.js")],
};
