import Icon from "@components/Ui/Icon";
import { Vendor } from "@contexts/vendor-context";
import { getImgProxyUrl } from "@utils/image-proxy";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

type Props = {
  onClick: () => void;
  vendor: Vendor | undefined;
  isSelected: boolean;
};

export default ({ onClick, vendor, isSelected = false }: Props) => {
  const { t } = useTranslation();
  const { name, vendorId, logo } = vendor || {};

  if (!vendor) return null;

  return (
    <button
      onClick={onClick}
      className={twMerge(
        "w-full space-x-3 rounded-md px-4 py-3 transition-colors duration-300 row hover:bg-gray-700 active:translate-y-px active:border-gray-500",
        isSelected && "bg-gray-700",
        !isSelected && "bg-gray-800",
      )}
    >
      <div className="relative aspect-square w-[24px] overflow-hidden rounded-sm bg-white row">
        {logo && (
          <img
            className="w-full p-0.5"
            src={getImgProxyUrl(logo, ["rs:fill:24:0"])}
            alt={t("vendor_logo", { vendor: name }) || ""}
          />
        )}
        {!logo && <Icon name="sesamy-simple" className="text-xl text-black" />}
      </div>
      <div className="flex-1 space-y-1 column-left">
        <div
          className={twMerge(
            "text-sm leading-none tracking-wide first-letter:uppercase",
            isSelected && "text-white",
            !isSelected && "text-gray-200",
          )}
        >
          {name || vendorId}
        </div>
      </div>

      {isSelected && <Icon name="checkmark" />}
    </button>
  );
};
