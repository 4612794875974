import { useRef, useState } from "react";
import { useSesamyAuthTokenContext } from "@contexts/sesamy-auth-token-context";
import TextInput from "@components/Ui/TextInput";
import Column from "@components/Ui/Column";
import useOnClickOutside from "@hooks/use-click-outside";
import { fetchSearchResults } from "@utils/global-search";
import { GlobalSearchResult } from "@type-defs/GlobalSearch";
import GlobalSearchGroup from "@components/GlobalSearchGroup";
import Icon from "@components/Ui/Icon";

let searchTimeout = setTimeout(() => {});

export default () => {
  const { accessToken } = useSesamyAuthTokenContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<GlobalSearchResult>();
  const [isSearching, setIsSearching] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const doSearch = async (query: string) => {
    if (!query || !accessToken) return;
    setIsSearching(true);

    const results = await fetchSearchResults({
      query,
      accessToken,
      limit: 5,
    });
    setSearchResults(results);

    setIsSearching(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSearchResults(undefined);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => doSearch(query), 500);
  };
  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleInputClear = () => {
    setSearchQuery("");
    setSearchResults(undefined);
  };

  useOnClickOutside(wrapperRef, () => {
    if (searchQuery) {
      setSearchQuery("");
    }
  });

  return (
    <div className="relative z-10 w-[400px]" ref={wrapperRef}>
      <TextInput
        value={searchQuery}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        placeholder="Search for users, contracts, bills"
        icon="search"
        slim
        isLoading={isSearching}
        type="search"
      />
      {searchQuery && (
        <button
          className="absolute right-3 top-1/2 flex -translate-y-1/2 cursor-default p-2"
          onClick={handleInputClear}
        >
          <Icon name="cross" className="h-2.5 w-2.5" />
        </button>
      )}
      {searchQuery && searchResults && (
        <div className="absolute -left-[400px] right-0 top-full mt-2 overflow-hidden rounded-md bg-gray-700 text-white shadow-lg">
          <div className="custom-scrollbar scrollbar-gray-700 max-h-[calc(100vh-80px)] overflow-y-scroll overscroll-none">
            {!isSearching && (
              <Column left className="gap-5 py-4 pl-5 pr-1">
                {searchResults && Object.keys(searchResults).length === 0 && (
                  <Column className="w-full gap-2 py-5 text-sm">
                    <div className="font-medium">No search results found</div>
                    <div>Please try again with a different search query</div>
                  </Column>
                )}
                {searchResults &&
                  Object.keys(searchResults).length > 0 &&
                  Object.keys(searchResults).map((key, i) => (
                    <GlobalSearchGroup
                      key={key}
                      index={i}
                      title={key}
                      searchQuery={searchQuery}
                      data={searchResults[key].data}
                      totalDocuments={searchResults[key].totalDocuments}
                    />
                  ))}
              </Column>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
