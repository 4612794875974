import { GlobalSearchResultItem } from "@type-defs/GlobalSearch";
import VendorLink from "@components/VendorLink";
import GlobalSearchItem from "@components/GlobalSearchItem";

type Props = {
  index: number;
  title: string;
  searchQuery: string;
  data: GlobalSearchResultItem[];
  totalDocuments: number;
};

export default ({ index, title, searchQuery, data, totalDocuments }: Props) => {
  const getViewAllLink = () => {
    switch (title) {
      case "Users":
        return `/customers?q=${searchQuery}`;
      case "Contracts":
        return `/customers/contracts?q=${searchQuery}`;
      default:
        return undefined;
    }
  };

  return (
    <>
      {index > 0 && <div className="w-full border-t border-gray-600" />}
      <div className="w-full">
        <div className="mb-2 px-1.5 pt-2 font-medium">
          {title}
          {getViewAllLink() && (
            <>
              {" "}
              -{" "}
              <VendorLink
                href={getViewAllLink()}
                className="font-normal text-primary"
              >
                View {totalDocuments && <>{totalDocuments} </>}results
              </VendorLink>
            </>
          )}
        </div>
        {data?.map((result: GlobalSearchResultItem) => (
          <GlobalSearchItem
            key={result.id || result.userId}
            title={title}
            result={result}
          />
        ))}
      </div>
    </>
  );
};
