import VendorLink from "@components/VendorLink";
import { GlobalSearchResultItem } from "@type-defs/GlobalSearch";
import { shortDateTime } from "@utils/numberFormat";
import StatusPill from "@components/Status/StatusPill";
import ContractBillStatusBadge from "@components/Contract/ContractBillStatusBadge";
import { BillStatusType } from "@type-defs/contracts";

type Props = {
  title: string;
  result: GlobalSearchResultItem;
};

export default ({ title, result }: Props) => {
  const getItemLink = (result: GlobalSearchResultItem) => {
    switch (title) {
      case "Users":
        return `/customers/${result.userId}`;
      case "Contracts":
        return `/customers/contracts/${result.id}`;
      case "Bills":
        return `/customers/contracts/${result.contractId}#${result.id}`;
      default:
        return undefined;
    }
  };

  const getUserName = () => {
    if (result.fullName?.trim() && result.fullName !== result.email) {
      return `${result.fullName} - ${result.email}`;
    }
    return result.email;
  };

  const UserDetails = () => {
    return (
      <>
        {getUserName()}{" "}
        <span className="ml-1 text-gray-200">
          {result.userId}
          {result.created && <> | {shortDateTime(result.created)}</>}
        </span>
      </>
    );
  };

  const ContractDetails = () => {
    return (
      <>
        {getUserName()}{" "}
        <span className="ml-1 text-gray-200">
          {result.title}{" "}
          {result.confirmedAt && <>| {shortDateTime(result.confirmedAt)}</>}
        </span>
        {result.status && (
          <span className="ml-3">
            <StatusPill status={result.status || ""} />
          </span>
        )}
      </>
    );
  };

  const BillDetails = () => {
    return (
      <>
        {getUserName()}{" "}
        <span className="ml-1 text-gray-200">
          {result.title}{" "}
          {result.billDate && <>| {shortDateTime(result.billDate)}</>}
        </span>
        {result.status && (
          <span className="ml-3 inline-flex">
            <ContractBillStatusBadge status={result.status as BillStatusType} />
          </span>
        )}
      </>
    );
  };

  return (
    <VendorLink
      href={getItemLink(result)}
      className="group -mb-0.5 block space-y-1 rounded-sm p-2 text-xs hover:bg-primary/10"
    >
      <span className="line-clamp-1 leading-loose">
        {title === "Users" && <UserDetails />}
        {title === "Contracts" && <ContractDetails />}
        {title === "Bills" && <BillDetails />}
      </span>
    </VendorLink>
  );
};
