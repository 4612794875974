const DEFAULT_FORMATTING_LOCALE = "en-US";

export const numberFormat = (
  value: number,
  shortened?: boolean,
  decimals?: number,
) => {
  let options: Intl.NumberFormatOptions = {};
  if (shortened) {
    options.notation = "compact";
  }
  options.minimumFractionDigits = 0;
  options.maximumFractionDigits = decimals;
  return new Intl.NumberFormat(DEFAULT_FORMATTING_LOCALE, options).format(
    value,
  );
};

export const currencyFormat = (
  value: number,
  coin: string,
  locale?: string,
) => {
  return new Intl.NumberFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    style: "currency",
    currency: coin,
  }).format(value);
};

export const dateFormat = (value: string, locale?: string) => {
  return new Intl.DateTimeFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(value));
};

export const customDateFormat = (
  value: string,
  options?: Intl.DateTimeFormatOptions,
  locale?: string,
) => {
  return new Intl.DateTimeFormat(
    locale || DEFAULT_FORMATTING_LOCALE,
    options,
  ).format(new Date(value));
};

export const shortDateFormat = (
  value: string,
  showYear = true,
  showMonth = true,
  locale?: string,
) => {
  return new Intl.DateTimeFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    year: showYear ? "numeric" : undefined,
    month: showMonth ? "short" : undefined,
    day: "2-digit",
  }).format(new Date(value));
};

export const longDateFormat = (value: string, locale?: string) => {
  return new Intl.DateTimeFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    month: "long",
    day: "numeric",
  }).format(new Date(value));
};

export const longDateTime = (value: string, locale?: string) => {
  return new Intl.DateTimeFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  }).format(new Date(value));
};

export const shortDateTime = (value: string, locale?: string) => {
  return new Intl.DateTimeFormat(locale || DEFAULT_FORMATTING_LOCALE, {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  }).format(new Date(value));
};

export const formatDateForQuery = (value: Date) => {
  return Intl.DateTimeFormat("sv-SE").format(value);
};

export const formatBytes = (bytes: number, decimals = 0) => {
  if (!bytes) return "0 bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [" bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};
