import { twMerge } from "tailwind-merge";

type StatusLabelProps = {
  status: string;
  pendingActivation?: boolean;
  className?: string;
};

export default ({
  status,
  pendingActivation,
  className = "",
}: StatusLabelProps) => {
  const isGreen = ["active", "trialing", "paid", "published", "valid"].includes(
    status.toLowerCase(),
  );
  const isYellow = ["paused", "pending", "publishable"].includes(
    status.toLowerCase(),
  );
  const isRed = [
    "expired",
    "deleted",
    "activationerror",
    "canceled",
    "inactive",
  ].includes(status.toLowerCase());
  const isPurple = ["refunded"].includes(status.toLowerCase());

  const isGray = !isGreen && !isYellow && !isRed;

  return (
    <div
      className={twMerge(
        "inline-block rounded-sm px-2 py-1.5 text-xs capitalize !leading-[100%] text-white sm:text-xs",
        isGray && "bg-gray-300 text-gray-900",
        isGreen && "bg-[#154337] text-[#15FFAB]",
        isYellow && "bg-[#413525] text-yellow",
        isRed && "bg-[#422227] text-[#FC5A5A]",
        isPurple && "bg-primary/75 text-white",
        className,
      )}
    >
      {pendingActivation ? "Activating..." : status.toLowerCase()}
    </div>
  );
};
