import styled from "styled-components";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const tw = tailwindStyles.theme;

const StyledDateRangePicker = styled.div`
  .rdrMonthAndYearWrapper {
    background-color: ${tw.colors.gray[800]};
    font-size: ${tw.fontSize.sm[0]};
  }
  .rdrNextPrevButton {
    background-color: transparent;
  }
  .rdrNextPrevButton i {
    border-width: 0 2px 2px 0;
    border-color: ${tw.colors.white};
    width: 10px;
    height: 10px;
  }
  .rdrPprevButton i {
    transform: rotate(135deg);
  }
  .rdrNextButton i {
    transform: rotate(-45deg);
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    width: 115px;
  }
  .rdrMonthPicker,
  .rdrMonthPicker select {
    text-align: right;
    background-color: ${tw.colors.gray[800]};
  }
  .rdrMonthPicker select,
  .rdrYearPicker select {
    color: ${tw.colors.white};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6'%3E%3Cpath d='M1.28.24A.71.71 0 0 0 .22.24c-.293.316-.293.829 0 1.144L4.499 6 8.78 1.382c.293-.316.293-.828 0-1.144a.71.71 0 0 0-1.061 0L4.499 3.711 1.28.24z' fill='%23bfbfbf'/%3E%3C/svg%3E");
    background-color: ${tw.colors.gray[800]};
  }
  .rdrCalendarWrapper {
    font-size: ${tw.fontSize.sm[0]};
  }
  .rdrMonth {
    background-color: ${tw.colors.gray[800]};
    width: 100%;
  }
  .rdrDateRangeWrapper .rdrMonth {
    width: 24em;
  }
  .rdrMonthName {
    padding-left: 8px;
    padding-right: 8px;
  }
  .rdrMonthName,
  .rdrWeekDay {
    color: ${tw.colors.gray[300]};
    font-weight: 600;
    font-size: ${tw.fontSize.base[0]};
  }
  .rdrDay {
    font-size: ${tw.fontSize.base[0]};
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: ${tw.colors.white};
  }
  .rdrDayToday .rdrDayNumber span:after {
    content: none;
  }
  .rdrDayDisabled {
    background-color: transparent;
  }
  .rdrDayNumber span {
    color: ${tw.colors.white};
  }
  .rdrDayDisabled .rdrDayNumber span,
  .rdrDayPassive .rdrDayNumber span {
    color: ${tw.colors.gray[300]};
  }

  .rdrSelected {
    border-radius: 5px;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: ${tw.colors.primary};
  }
  .rdrStartEdge,
  .rdrDayStartPreview {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .rdrEndEdge,
  .rdrDayEndPreview {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .rdrInRange {
    background-color: ${tw.colors.gray[500]};
  }
  .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrInRange,
  .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrInRange,
  .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrInRange,
  .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrInRange,
  .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrStartEdge,
  .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rdrDayEndOfMonth .rdrStartEdge.rdrEndEdge,
  .rdrDayEndOfWeek .rdrStartEdge.rdrEndEdge {
    border-radius: 5px;
  }

  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) {
    width: 340px;
    background: transparent;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${tw.colors.gray[700]};
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
    .rdrDayHovered
    .rdrDayNumber:after {
    display: none;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrMonthAndYearPickers {
    width: 100px;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDay {
    height: 2.6em;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrMonth {
    padding-bottom: 1em;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`;

export default StyledDateRangePicker;
